import parse from "html-react-parser";
import React, { useMemo } from "react";
import { Dialog, Modal, XIcon } from "staysure-component-library";

import LabelButtonWrapper, {
  IconButtonWrapper,
} from "@/components/atoms/button-wrapper/button-wrapper";
import { ErrorObject } from "@/types/ApiErrorType";

import Style from "./api-error-modal.module.css";

type APIErrorModalProps = {
  title: string;
  description: string;
  ctaPrimaryBtnLabel?: string;
  ctaSecondaryBtnLabel?: string;
  validationEnable?: boolean;
  errors?: ErrorObject[] | null;
  isOpenModal?: boolean;
  isSecondaryCtaEnabled?: boolean;
  onOpenChange?: (value: boolean) => void;
  onPrimaryCtaClick?: (value: boolean) => void;
  onSecondaryCtaClick?: () => void;
};

function APIErrorModal(props: Readonly<APIErrorModalProps>) {
  const {
    title,
    description,
    ctaPrimaryBtnLabel,
    ctaSecondaryBtnLabel,
    validationEnable,
    errors,
    isOpenModal,
    isSecondaryCtaEnabled,
    onOpenChange,
    onPrimaryCtaClick,
    onSecondaryCtaClick,
  } = props;

  const buttonStyles = useMemo(() => {
    if (isSecondaryCtaEnabled)
      return {
        buttonContainer: `flex flex-col md:flex-row items-start self-stretch gap-spacing-xs`,
        button: `min-w-[279px] md:min-w-[148px] h-size-xl w-full  text-[18px] font-proximanova min-h-[48px] font-[600] leading-[100%] gap-spacing-xs md:gap-[2px]`,
      };
    return {
      buttonContainer: ``,
      button: `min-w-[278px] md:min-w-[304px] h-size-xl w-full text-[18px] font-proximanova min-h-[48px] font-[600] leading-[100%] gap-spacing-xs md:gap-[2px]`,
    };
  }, [isSecondaryCtaEnabled]);

  return (
    <Modal
      modalStyles={{
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
      }}
      className={` flex flex-col max-w-[327px] md:min-h-[318px] px-spacing-l py-spacing-l items-center gap-spacing-l bg-surface-neutral-option-1 rounded-lg  relative
                md:max-w-[416px] md:px-spacing-xxxl md:py-spacing-xxxl`}
      uderlayClassName="z-[201] bg-interactive-overlay-default"
      isOpen={isOpenModal}
      onOpenChange={(value) => {
        if (onOpenChange) onOpenChange(value);
      }}
    >
      <div
        className={`items-center justify-center px-spacing-xxs py-spacing-xxs gap-spacing-xs w-auto h-auto ${Style.customRadius} absolute top-0 right-0`}
      >
        {/* Close icon  */}
        <IconButtonWrapper
          data-testid="error-modal-close-icon-button"
          id="error-modal-close-icon-button"
          hierarchy="tertiary"
          btnType="icon"
          icon={
            <XIcon stroke="#306582" testId="closeAPIErrorModalIcon-testid" />
          }
          className="w-[48px] h-[48px] rounded-radius-lg py-spacing-s px-spacing-m gap-spacing-xs text-[24px] text-text-on-primary"
          onClick={() => {
            if (onOpenChange) onOpenChange(false);
          }}
        />
      </div>

      <Dialog className="relative gap-spacing-l">
        <div className="flex flex-col items-center gap-spacing-xs self-stretch min-w-[279px] min-h-[118px] w-full h-full pb-spacing-l">
          {/* Title */}
          <h1
            className={`text-[23px] font-dmserif not-italic font-normal text-center leading-[27.6px] text-text-title
                        md:text-[31px] md:leading-[34.1px]`}
          >
            {title && parse(title)}
          </h1>
          {/* Description */}
          {validationEnable && errors && errors?.length > 0 ? (
            <ul
              className={`text-center self-stretch text-text-body font-proximanova text-[18px] font-normal leading-[27px] ${errors?.length > 1 && " list-disc"}`}
            >
              {errors?.map((error) => {
                return <li key={`${error?.message}`}>{error?.message}</li>;
              })}
            </ul>
          ) : (
            <p
              className={`text-center self-stretch text-text-body  font-proximanova text-[18px] font-normal leading-[27px] `}
            >
              {description && parse(description)}
            </p>
          )}
        </div>

        <div className={`${buttonStyles.buttonContainer}`}>
          <LabelButtonWrapper
            data-testid="error-modal-primary-button"
            id="error-modal-primary-button"
            hierarchy="primary"
            className={`${buttonStyles.button} text-text-on-primary`}
            label={ctaPrimaryBtnLabel ?? "Back"}
            onClick={() => {
              if (onPrimaryCtaClick) onPrimaryCtaClick(false);
            }}
          />

          {isSecondaryCtaEnabled && (
            <LabelButtonWrapper
              data-testid="error-modal-secondary-button"
              id="error-modal-secondary-button"
              hierarchy="secondary"
              className={`${buttonStyles.button}`}
              label={ctaSecondaryBtnLabel ?? "Action"}
              onClick={() => {
                if (onSecondaryCtaClick) onSecondaryCtaClick();
              }}
            />
          )}
        </div>
      </Dialog>
    </Modal>
  );
}

export default APIErrorModal;

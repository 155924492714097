import { useMemo, useState } from "react";
import { Button } from "staysure-component-library";

import {
  ErrorButtonWrapperProps,
  IconButtonWrapperProps,
  LabelButtonWrapperProps,
} from "@/types/commonTypes";

import styles from "./buttonWrapper.module.css";

type EnhancedLabelButtonWrapperProps = LabelButtonWrapperProps & {
  type?: "button" | "submit" | "reset";
  onBlur?: () => void;
  isClickableDisabled?: boolean;
  form?: string;
};

function LabelButtonWrapper(props: Readonly<EnhancedLabelButtonWrapperProps>) {
  const {
    label,
    hierarchy,
    semanticColor,
    onClick,
    className,
    isDisabled,
    isClickableDisabled,
    ...others
  } = props;

  const [isKeyBoardFocused, setIsKeyBoardFocused] = useState(true);

  const isClickableDisabledStyle = useMemo(() => {
    if (isClickableDisabled) {
      return `!text-text-disabled !bg-surface-neutral-option-5`;
    }
    return `bg-interactive-primary-default-1 text-neutral-900 
            hover:bg-interactive-primary-hovered hover:border-interactive-primary-hovered
            active:bg-interactive-primary-pressed-2 active:border-bg-interactive-primary-pressed-2`;
  }, [isClickableDisabled]);

  const style = useMemo(() => {
    if (hierarchy === "primary") {
      if (semanticColor === "Dark Background") return "";
      return `${styles.primary} ${isClickableDisabledStyle}  border-interactive-primary-default-1 w-full 
       h-[56px] rounded-radius-pill text-lg font-semibold font-proximanova border-[1.5px]
       flex flex-col justify-center items-center gap-grid-system-space-050
       disabled:text-text-disabled disabled:bg-surface-neutral-option-5 border-none outline-none`;
    }
    if (hierarchy === "secondary") {
      if (semanticColor === "Dark Background")
        return `${styles.secondary} border-surface-neutral-option-1 text-surface-neutral-option-1
      hover:bg-surface-neutral-option-1 hover:border-interactive-secondary-pressed hover:text-interactive-secondary-default w-[160px] h-[40px] rounded-radius-pill 
      text-lg font-semibold font-proximanova border-[1.5px]  
      active:bg-interactive-secondary-pressed active:border-bg-surface-neutral-option-1 active:text-surface-neutral-option-1 
      flex flex-col justify-center items-center gap-grid-system-space-050
      focus:!border-surface-neutral-option-1 outline-none`;
      return `${styles.secondary} border-interactive-secondary-default text-interactive-secondary-default
       hover:border-border-accent-1-(secondary) w-full hover:bg-interactive-action-hovered
       h-[56px] rounded-radius-pill text-lg font-semibold font-proximanova border-[1.5px]  
       active:bg-interactive-secondary-default active:border-bg-interactive-secondary-default active:text-dark-text-on-primary 
       flex flex-col justify-center items-center gap-grid-system-space-050
       focus:border-border-strong outline-none `;
    }
    if (hierarchy === "tertiary") {
      if (semanticColor === "Dark Background") {
        return `
      ${styles.tertiary_dark} border-none bg-none px-[12px] text-dark-text-on-primary relative hover:text-interactive-secondary-default
      focus:rounded-radius-pill focus:border-solid outline-none`;
      }
      return `
      ${styles.tertiary_light} border-none bg-none px-[12px] text-interactive-secondary-default relative hover:text-dark-text-on-primary
      focus:rounded-radius-pill focus:border-solid outline-none`;
    }
    return "";
  }, [hierarchy, isClickableDisabledStyle, semanticColor]);

  const handleClick = () => {
    if (onClick !== undefined) {
      onClick();
    }
  };

  const handleOnPressStart = (e: { pointerType: string }) => {
    if (e.pointerType === "keyboard") {
      setIsKeyBoardFocused(true);
    } else {
      setIsKeyBoardFocused(false);
    }
  };

  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...others}
      btnType="label"
      label={label}
      type={props?.type ?? "button"}
      isDisabled={isDisabled}
      className={`${style} ${className} ${styles.focus_class} ${isKeyBoardFocused ? "keyBoardFocused" : ""}`}
      hierarchy={hierarchy}
      semanticColor={semanticColor}
      onClick={() => handleClick()}
      onFocusChange={(e) => setIsKeyBoardFocused(e)}
      onPressStart={(e: { pointerType: string }) => handleOnPressStart(e)}
    />
  );
}

function IconButtonWrapper(
  props: Readonly<
    IconButtonWrapperProps & { type?: "button" | "submit" | "reset" } & {
      onBlur?: () => void;
      "aria-label"?: string;
    }
  >
) {
  const {
    icon,
    hierarchy,
    semanticColor,
    onClick,
    className,
    "aria-label": ariaLabel,
    ...others
  } = props;

  const [isKeyBoardFocused, setIsKeyBoardFocused] = useState(true);

  const style = useMemo(() => {
    if (hierarchy === "primary") {
      if (semanticColor === "Dark Background") return "";
      return `${styles.primary} bg-interactive-primary-default-1 border-interactive-primary-default-1
       hover:bg-interactive-primary-hovered hover:border-interactive-primary-hovered
       h-[56px] rounded-radius-pill font-semibold font-proximanova border-[1.5px]
       active:bg-interactive-primary-pressed-2 active:border-bg-interactive-primary-pressed-2 
       flex flex-col justify-center items-center outline-none`;
    }
    if (hierarchy === "secondary") {
      if (semanticColor === "Dark Background")
        return `${styles.secondary} ${styles.icon_btn_secondary_dark} 
      border-icon-on-dark rounded-radius-pill 
      hover:bg-dark-border-strong hover:border-icon-on-dark 
      active:bg-interactive-secondary-default active:border-interactive-secondary-default
      focus:outline-offset-4 focus:outline focus:outline-interactive-focus-default focus:border-surface-accent-1-(main)
      flex flex-col justify-center 
      border-[1.5px]
      `;
      return `${styles.secondary} ${styles.icon_btn_secondary} border-interactive-secondary-default text-interactive-secondary-default
       hover:border-interactive-secondary-pressed border-[1.5px] hover:bg-surface-neutral-option-1
       h-[56px] rounded-radius-pill font-semibold font-proximanova 
       active:bg-interactive-secondary-default active:border-bg-interactive-secondary-default active:text-dark-text-on-primary 
       flex flex-col justify-center items-center outline-none `;
    }
    if (hierarchy === "tertiary") {
      if (semanticColor === "Dark Background") {
        return `${styles.tertiary_dark} bg-interactive-primary-default-1 border-interactive-primary-default-1
      hover:bg-interactive-primary-hovered hover:border-interactive-primary-hovered
      h-[56px] rounded-radius-pill font-semibold font-proximanova border-[1.5px]  
      active:bg-interactive-primary-pressed-2 active:border-bg-interactive-primary-pressed-2 
      flex flex-col justify-center items-center outline-none`;
      }
      return ` ${styles.tertiary_light} ${styles.tertiary_light_icon} flex items-center justify-center border-none px-[12px] text-interactive-secondary-default relative hover:text-dark-text-on-primary
      rounded-radius-pill focus:border-solid outline-none hover:bg-interactive-action-hovered px-spacing-m py-spacing-m active:bg-interactive-secondary-default`;
    }
    return "";
  }, [hierarchy, semanticColor]);

  const handleClick = () => {
    if (onClick !== undefined) {
      onClick();
    }
  };

  const handleOnPressStart = (e: { pointerType: string }) => {
    if (e.pointerType === "keyboard") {
      setIsKeyBoardFocused(true);
    } else {
      setIsKeyBoardFocused(false);
    }
  };
  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...others}
      btnType="icon"
      icon={icon}
      type={props?.type ?? "button"}
      isDisabled={props?.isDisabled}
      className={`${style} ${className} ${styles.focus_class} ${isKeyBoardFocused ? "keyBoardFocused" : ""}`}
      hierarchy={hierarchy}
      semanticColor={semanticColor}
      onClick={() => handleClick()}
      onFocusChange={(e) => setIsKeyBoardFocused(e)}
      onPressStart={(e: { pointerType: string }) => handleOnPressStart(e)}
      aria-label={ariaLabel ?? "iconButton"}
    />
  );
}

function ErrorButtonWrapper(
  props: Readonly<
    ErrorButtonWrapperProps & { type?: "button" | "submit" | "reset" }
  >
) {
  const {
    label,
    hierarchy,
    semanticColor,
    onClick,
    className,
    isDisabled,
    ...others
  } = props;

  const [isKeyBoardFocused, setIsKeyBoardFocused] = useState(true);

  const style = useMemo(() => {
    if (hierarchy === "primary") {
      if (semanticColor === "Dark Background") return "";
      return `${styles.primary} bg-interactive-primary-default-1 border-interactive-primary-default-1
       hover:bg-interactive-primary-hovered hover:border-interactive-primary-hovered text-neutral-900 w-full 
       h-[56px] rounded-radius-pill text-lg font-semibold font-proximanova border-[1.5px]
       active:bg-interactive-primary-pressed-2 active:border-bg-interactive-primary-pressed-2 
       flex flex-col justify-center items-center gap-grid-system-space-050
       disabled:text-text-disabled disabled:bg-surface-neutral-option-5 border-none outline-none`;
    }
    if (hierarchy === "secondary") {
      if (semanticColor === "Dark Background")
        return `${styles.secondary} border-surface-neutral-option-1 text-surface-neutral-option-1
      hover:border-interactive-secondary-hovered w-[160px] h-[40px] rounded-radius-pill 
      text-lg font-semibold font-proximanova border-[1.5px]  
      active:bg-interactive-secondary-pressed active:border-bg-surface-neutral-option-1 active:text-surface-neutral-option-1 
      flex flex-col justify-center items-center gap-grid-system-space-050
     focus:border-border-strong outline-none`;
      return `${styles.secondary} border-interactive-secondary-default text-interactive-secondary-default
       hover:border-interactive-secondary-hovered w-full 
       h-[56px] rounded-radius-pill text-lg font-semibold font-proximanova border-[1.5px]  
       active:bg-interactive-secondary-pressed active:border-bg-interactive-secondary-pressed active:text-dark-text-on-primary 
       flex flex-col justify-center items-center gap-grid-system-space-050
      focus:border-border-strong outline-none`;
    }
    if (hierarchy === "tertiary") {
      if (semanticColor === "Dark Background") {
        return `
      ${styles.tertiary_dark} border-none bg-none px-[12px] text-dark-text-on-primary relative hover:text-interactive-secondary-default
      focus:rounded-radius-pill focus:border-solid outline-none`;
      }
      return `
      ${styles.tertiary_light_error} border-none bg-none px-[12px] text-interactive-secondary-default relative hover:text-dark-text-on-primary
      focus:rounded-radius-pill focus:border-solid outline-none`;
    }
    return "";
  }, [hierarchy, semanticColor]);

  const handleClick = () => {
    if (onClick !== undefined) {
      onClick();
    }
  };

  const handleOnPressStart = (e: { pointerType: string }) => {
    if (e.pointerType === "keyboard") {
      setIsKeyBoardFocused(true);
    } else {
      setIsKeyBoardFocused(false);
    }
  };

  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...others}
      btnType="label"
      label={label}
      type={props?.type ?? "button"}
      isDisabled={isDisabled}
      className={`${style} ${className} ${styles.focus_class} ${isKeyBoardFocused ? "keyBoardFocused" : ""}`}
      hierarchy={hierarchy}
      semanticColor={semanticColor}
      onClick={() => handleClick()}
      onFocusChange={(e) => setIsKeyBoardFocused(e)}
      onPressStart={(e: { pointerType: string }) => handleOnPressStart(e)}
    />
  );
}

export default LabelButtonWrapper;

export { IconButtonWrapper, ErrorButtonWrapper };

"use client";

import { redirect, useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useDispatch, useSelector } from "react-redux";

import APIErrorModal from "@/components/molecules/api-error-modal/api-error-modal";
import LoadingOverlay from "@/components/molecules/loading-overlay/loading-overlay";
import { NotFound } from "@/config/app-constant";
import { RootState } from "@/Redux/root-reducer";
import { clearApiError } from "@/Redux/Slices/errors/apiErrorSlice";
import { clearQuestion } from "@/Redux/Slices/question/question.slice";

import DatadogInit from "./datadog-init";

export default function Template({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  const dispatch = useDispatch();
  const router = useRouter();
  const {
    errorStatus,
    modalTitle,
    modalDescription,
    ctaPrimaryBtnLabel,
    ctaSecondaryBtnLabel,
    multiButton,
    httpCode,
    errorCode,
    errors,
    validationEnable,
    onPrimaryCtaClick,
  } = useSelector((state: RootState) => state.apiError);

  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (errorStatus && httpCode === 401) {
      dispatch(clearApiError());
      dispatch(clearQuestion());
      router.replace("/");
    } else if (errorStatus) {
      setIsOpenModal(true);
    }

    // if (!isOpenModal) dispatch(clearApiError());
  }, [dispatch, isOpenModal, errorStatus, httpCode, router]);

  const handleModalClose = (value: boolean) => {
    dispatch(clearApiError());
    setIsOpenModal(value);
  };

  const handlePrimaryButtonClick = (value: boolean) => {
    if (errorCode && errorCode === "456") {
      window.location.href = "/";
    }
    dispatch(clearApiError());
    setIsOpenModal(value);
  };

  const handleContactUs = () => {
    if (multiButton) {
      dispatch(clearApiError());
      setIsOpenModal(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).zE.apply(null, ["messenger", "show"]);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).zE.apply(null, ["messenger", "open"]);
    }
  };

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENV === "production") {
      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID ?? "" });
    }

    if (window) {
      if (window.location.pathname === "/404/") {
        redirect(NotFound);
      }
    }
  }, []);

  useEffect(() => {
    const handleConsentOneTrust = () => {
      // e contains information about the event, you can use it if needed
      const element = document.getElementById("ot-sdk-btn-floating");
      if (element) {
        element.style.display = "none";
      }
    };

    // Add event listener for "consent.onetrust"
    window.addEventListener("consent.onetrust", handleConsentOneTrust);

    // Clean up function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("consent.onetrust", handleConsentOneTrust);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (window && (window as any).zE) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).zE.apply(null, ["messenger", "hide"]);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).zE.apply(null, [
          "messenger:on",
          "close",
          function f() {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).zE.apply(null, ["messenger", "hide"]);
          },
        ]);
      }
    }, 100);
  }, []);

  return (
    <>
      {process.env.NEXT_PUBLIC_DD_ENABLED === "true" && <DatadogInit />}
      <LoadingOverlay />
      <div>
        {isOpenModal && (
          <APIErrorModal
            title={modalTitle}
            description={modalDescription}
            ctaPrimaryBtnLabel={ctaPrimaryBtnLabel}
            ctaSecondaryBtnLabel={ctaSecondaryBtnLabel}
            isSecondaryCtaEnabled={multiButton}
            errors={errors}
            isOpenModal={isOpenModal}
            validationEnable={validationEnable}
            onOpenChange={(value) => handleModalClose(value)}
            onPrimaryCtaClick={(value) =>
              onPrimaryCtaClick
                ? onPrimaryCtaClick({ errorCode, httpCode, errors })
                : handlePrimaryButtonClick(value)
            }
            onSecondaryCtaClick={() => handleContactUs()}
          />
        )}
        {children}
      </div>
    </>
  );
}

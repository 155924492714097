/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ApiErrorProps } from "@/types/ApiErrorType";
import { QuestionPayload } from "@/types/QuestionPayloadType";
import { modifyQuestionQuotePayload } from "@/utils/common.utils";

import {
  getQuoteData,
  createQuoteOnQuestion,
  updateQuoteOnQuestion,
} from "./question.action";

interface QuoteState {
  quoteResponse: QuestionPayload | null;
  loading: boolean;
  error: ApiErrorProps | null;
}
const initialState: QuoteState = {
  quoteResponse: {
    type: "IntermediateData",
    channel: "ONLINE",
    attributes: {
      domain: "STS",
      lastVisitedPage: "test",
      organiser: null,
      tripDetails: null,
      travellerDetails: null,
      marketingConsent: null,
    },
  } as unknown as QuestionPayload | null,
  loading: false,
  error: null,
};

const questionSlice = createSlice({
  name: "question",
  initialState: initialState as QuoteState,
  reducers: {
    clearQuestion: () => {
      return initialState;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setQuestionData: (state: QuoteState, action: PayloadAction<any>) => {
      const quotoEditResponse = modifyQuestionQuotePayload(action?.payload);
      return {
        ...state,
        quoteResponse: quotoEditResponse,
        loading: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuoteData.pending, (state) => {
        state.loading = true; // Update the state with the response data
        return state;
      })
      .addCase(getQuoteData.fulfilled, (state, action) => {
        state.loading = false;
        if (action?.payload?.referenceId) {
          const quotoEditResponse = modifyQuestionQuotePayload(action?.payload);
          state.quoteResponse = quotoEditResponse || initialState.quoteResponse;
        } else {
          state.quoteResponse = action.payload || initialState.quoteResponse;
        }
        return state;
        // Update the state with the response data
      })
      .addCase(createQuoteOnQuestion.pending, (state) => {
        state.loading = false; // Update the state with the response data
        return state;
      })
      .addCase(createQuoteOnQuestion.fulfilled, (state, action) => {
        state.loading = false;
        const quotoSaveResponse = modifyQuestionQuotePayload(action?.payload);
        state.quoteResponse = quotoSaveResponse || initialState.quoteResponse;
        return state;
      })
      .addCase(updateQuoteOnQuestion.pending, (state) => {
        state.loading = false; // Update the state with the response data
        return state;
      })
      .addCase(updateQuoteOnQuestion.fulfilled, (state, action) => {
        state.loading = false;
        const quotoEditResponse = modifyQuestionQuotePayload(action?.payload);
        state.quoteResponse = quotoEditResponse || initialState.quoteResponse;
        return state;
      })
      .addCase(getQuoteData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as ApiErrorProps;
        return state;
      })
      .addCase(createQuoteOnQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as ApiErrorProps;
        return state;
      })
      .addCase(updateQuoteOnQuestion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as ApiErrorProps;
        return state;
      });
  },
});
export const { setQuestionData, clearQuestion } = questionSlice.actions;
export default questionSlice.reducer;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ApiErrorProps } from "../../../types/ApiErrorType";
import {
  createQuoteOnQuestion,
  getQuoteData,
  saveQuestionDataBFF,
  sessionCheck,
  updateQuoteOnQuestion,
} from "../question/question.action";

const initialState: ApiErrorProps = {
  httpCode: null,
  errorCode: "",
  errorMessage: "",
  errorStatus: false,
  errors: null,
  modalTitle: "Something <br> went wrong",
  modalDescription:
    "Please try again. If the problem persists, please contact us.",
  ctaPrimaryBtnLabel: "Try again",
  ctaSecondaryBtnLabel: "Contact us",
  multiButton: false,
};

const mergeDefaultProps = (payload: ApiErrorProps) => ({
  ...initialState,
  ...payload,
});

const apiError = createSlice({
  name: "apiError",
  initialState,
  reducers: {
    setApiError: (state, action: PayloadAction<typeof initialState>) => {
      return mergeDefaultProps(action.payload);
    },

    clearApiError: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuoteData.rejected, (state, action) => {
        state = mergeDefaultProps(action.payload as ApiErrorProps);
        return state;
      })
      .addCase(createQuoteOnQuestion.rejected, (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state = mergeDefaultProps(action.payload as ApiErrorProps);
        return state;
      })
      .addCase(updateQuoteOnQuestion.rejected, (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state = mergeDefaultProps(action.payload as ApiErrorProps);
        return state;
      })
      .addCase(saveQuestionDataBFF.rejected, (state, action) => {
        state = mergeDefaultProps(action.payload as ApiErrorProps);
        return state;
      })
      .addCase(sessionCheck.rejected, (state, action) => {
        state = mergeDefaultProps(action.payload as ApiErrorProps);
        return state;
      });
  },
});

export const { setApiError, clearApiError } = apiError.actions;
export default apiError.reducer;
